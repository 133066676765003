import axios from "axios";
import { store } from "react-notifications-component";
import { messaging } from "../init-fcm";
import Routes from '@Routes'
export const handlerAddAppNotification = (notification) => {
  // e.preventDefault()
  const { title, body } = notification;
  // console.log('NOOOOOT',title,body)
  store.addNotification({
    title: title,
    message: body,
    type: "warning",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
      showIcon: true,
      onScreen: true,
      pauseOnHover: true,
    },
  });
};
export const initializeFirebaseBackGroundApp = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("./firebase-messaging-sw.js")
      .then(function (registration) {
        // console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function (err) {
        // console.log("Service worker registration failed, error:", err);
      });
  }
};
export const initializeFirebaseToken = () => {
  messaging
    .requestPermission()
    .then(async function () {
      const token = await messaging.getToken();
      console.log("token", token)
      axios
        .post(Routes.Notification.sentToken, { firebase_UID: token })
        .then((res) => {
          console.log("token", res.data.status);
        });
    })
    .catch(function (err) {
      console.log("Unable to get permission to notify.", err);
    });
};
export const getFirebaseMessage = () => {
  let handler = null;
  navigator.serviceWorker.addEventListener("message", (message) => {
    const notification = message.data.notification;
    // console.log(notification)
    handler = notification;
  });
  return handler;
};
