import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyCtcB7w8VqKHhAORieeQM7iZtfqEWZoDpY",
  authDomain: "caram-9aac3.firebaseapp.com",
  projectId: "caram-9aac3",
  storageBucket: "caram.appspot.com",
  messagingSenderId: "548957041002",
  appId: "1:548957041002:web:813b2989aee77df6fb3513",
  measurementId: "G-X85GMQVJ88"
};

// Initialize Firebase
const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);
// init messaging
const messaging = initializedFirebaseApp.messaging();

export { messaging };
