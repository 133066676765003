// ** Initial State
const initialState = {
  allBrands: [],
}

const brands = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_BRANDS':
      return { ...state, allBrands: action.data }
    default:
      return { ...state }
  }
}

export default brands
