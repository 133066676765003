// **  Initial State
const initialState = {
    gift: ''
  }
  
  const tempGiftReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SetTempGift':
        return { ...state, gift: action.payload.gift }
      default:
        return state
    }
  }
  
  export default tempGiftReducer
  