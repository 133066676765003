// **  Initial State
const initialState = {
  value: ''
}

const tempEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SetTempEmail':
      return { ...state, value: action.payload.value }
    default:
      return state
  }
}

export default tempEmailReducer
