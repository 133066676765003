// ** React Imports
import React, { useCallback } from 'react'

import { useState, createContext } from "react";

// ** Intl Provider Import
import { IntlProvider } from "react-intl";

import { useRTL } from "@hooks/useRTL";
// ** Core Language Data
import messagesEn from "@assets/data/locales/en.json";
import messagesSa from "@assets/data/locales/sa.json";

// ** User Language Data
import userMessagesEn from "@src/assets/data/locales/en.json";
import userMessagesSa from "@src/assets/data/locales/sa.json";
import { useDispatch } from 'react-redux';

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn },
  sa: { ...messagesSa, ...userMessagesSa },
};

// ** Create Context
const Context = createContext();

const IntlProviderWrapper = ({ children }) => {
  const dispatch = useDispatch()
  const changeLanguage = useCallback((lang) => dispatch({ type: 'changeLanguage', payload: { lang } }), [dispatch])

  // ** States
  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState(menuMessages["en"]);
  const [isRTL, setIsRtl] = useRTL();
  // ** Switches Language
  const switchLanguage = (lang) => {
    if (lang === "sa") setIsRtl(1);
    else setIsRtl(0);
    setLocale(lang);
    changeLanguage(lang)
    setMessages(menuMessages[lang]);
  };

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export { IntlProviderWrapper, Context as IntlContext };
