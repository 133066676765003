// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import lang from "./lang";
import data from "./data";
import temp from "./temp";
import tempEmail from './tempEmail'
import tempCode from './tempCode'
import tempGift from './tempGift'
import navbar from "./navbar";
import layout from "./layout";
import chat from "@src/views/apps/chat/store/reducer";
import todo from "@src/views/apps/todo/store/reducer";
import users from "@src/views/apps/user/store/reducer";
import brands from "@src/views/apps/Cars/store/reducer";
// import sliders from "@src/views/apps/slider/store/reducer";
import email from "@src/views/apps/email/store/reducer";
import invoice from "@src/views/apps/invoice/store/reducer";
import calendar from "@src/views/apps/calendar/store/reducer";
import ecommerce from "@src/views/apps/ecommerce/store/reducer";
import dataTables from "@src/views/tables/data-tables/store/reducer";
import notification from './notification'

const rootReducer = combineReducers({
  auth,
  lang,
  data,
  temp,
  tempEmail,
  tempCode,
  notification,
  tempGift,
  todo,
  chat,
  email,
  users,
  brands,
  // sliders,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
});

export default rootReducer;
